import { createSlice } from "@reduxjs/toolkit";

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    menuList: [],
    loading: false,
    error: null,
  },
  reducers: {
    menuStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    menuSuccess: (state, action) => {
      state.loading = false;
      state.menuList = action.payload.data;
    },
    menuFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    
  },
});

export const {
  menuStart,
  menuSuccess,
  menuFailure,
} = menuSlice.actions;
export default menuSlice.reducer;

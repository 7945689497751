
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './settings.css';
import HttpApis from "../../services/HttpApis";

const Settings = () => {
  
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchPages = async () => {
        try {
        
          const response = await HttpApis.getPages();
          if (response.data.status) {
            setPages(Object.values(response.data.data)); // Convert the object to an array
          }
        } catch (err) {
          setError('Failed to fetch pages. Please try again later.');
        } finally {
          setLoading(false);
        }
      };
  
      fetchPages();
    }, []);
  
    if (loading) {
      return <div className="loading">Loading...</div>;
    }
  
    if (error) {
      return <div className="error">{error}</div>;
    }
  
    return (
        <div className="pages-container">
        {pages.map((page) => (
          <div key={page.id} className="page-card">
            <a
              href={`/${page.page_slug}`}
              target="_blank"
              rel="noopener noreferrer"
              className="page-link"
            >
              <h2 className="page-title">{page.page_title}</h2>
            </a>
            <p className="page-content">{page.page_content}</p>
          </div>
        ))}
      </div>
    );
  };
export default Settings;

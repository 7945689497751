import React from "react";
import { useEpg } from "planby";
import { theme } from '../components/EpgGuide/theme';
import HttpApis from "../services/HttpApis"; 

export function useApp() {
  const [channels, setChannels] = React.useState([]);
  const [epg, setEpg] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const channelsData = React.useMemo(() => channels, [channels]);
  const epgData = React.useMemo(() => epg, [epg]);

  const today = new Date();
  const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}T00:00:00`;
  };

  const startDate = formatDate(today);
  const endDate = formatDate(tomorrow);

  const { getEpgProps, getLayoutProps } = useEpg({
    channels: channelsData,
    epg: epgData,
    dayWidth: 7200,
    sidebarWidth: 100,
    itemHeight: 80,
    isSidebar: true,
    isTimeline: true,
    isLine: true,
    startDate: startDate,
    endDate: endDate,
    isBaseTimeFormat: true,
    theme
  });

  const transformChannel = (channel) => {
    return {
      uuid: channel.id,
      type: "channel",
      title: channel.name,
      country: "USA",
      provider: 7427,
      logo: channel.logo,
      year: 2024
    };
  };

  const transformProgramData = (program) => {
    return {
      id: program.id,
      description: "desc",
      title: program.video.title,
      isYesterday: false,
      since: program.start_datetime,
      till: program.end_datetime,
      channelUuid: program.channel_id,
      image: program.video.thumbnail,
      country: "USA",
      Year: "2021–",
      Rated: "TV-14",
      Released: "29 Dec 2021",
      Runtime: "N/A",
      Genre: "Action, Adventure, Sci-Fi",
      Director: "N/A",
      Writer: "Jon Favreau",
      Actors: "Temuera Morrison, Ming-Na Wen, Matt Berry",
      Language: "English",
      Country: "United States",
      Awards: "N/A",
      Metascore: "N/A",
      imdbRating: "8.0",
      imdbVotes: "20,147",
      imdbID: "tt13668894",
      Type: "series",
      totalSeasons: "1",
      Response: "True",
      Ratings: [
        {
          Source: "Internet Movie Database",
          Value: "8.0/10"
        }
      ],
      rating: 3
    };
  };

  const handleFetchResources = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await HttpApis.getChanelsWithScheduls();
      const channels = [];
      const epg = [];

      response.data.data.forEach(element => {
        channels.push(transformChannel(element));
        element.video_schedules.forEach(schedule => {
          if (schedule.video !== null) {
            epg.push(transformProgramData(schedule));
          }
        });
      });

      setChannels(channels);
      setEpg(epg);
    } catch (error) {
      console.error('Error fetching channels and schedules:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    handleFetchResources();
  }, [handleFetchResources]);

  return { getEpgProps, getLayoutProps, isLoading };
}
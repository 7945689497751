import styled from "styled-components";

// Grid container with 5 columns and spacing
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  padding: 16px;
`;

// Individual card box for each item
export const CardBox = styled.div`
  background-color: #000;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  color: #ffffff;
`;

// Image container with 16:9 aspect ratio
export const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
  border-radius: 8px;
  background-color: #000;

  .imagebox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

// Text description below each image
export const Para = styled.div`
  margin-top: 8px;
  font-size: 0.9em;
  color: #ddd;
`;
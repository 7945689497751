import React, {useEffect} from "react";
import Banner from "../Banner/bannner";
import Standard from "../Standard/standard";
import Guide from "../EpgGuide/Guide";
import useSectionHook from "../../hooks/SectionHook";

const Home_page = ({ selectedSectionID, sectionData, handleDetails }) => {
  useEffect(() => {
  
  }, [selectedSectionID, sectionData]);

  return (
    <div>
      {selectedSectionID === 100 ? (
        <Guide />
      ) : (
        Array.isArray(sectionData) &&
        sectionData.map((item) => (
          <React.Fragment key={item.id}>
            {item?.type === "Carousel" ? (
              <Banner
                selectedSectionID={selectedSectionID}
                sectionData={sectionData}
                handleDetails={handleDetails}
              />
            ) : (
              <Standard sectionData={sectionData} handleDetails={handleDetails} />
            )}
          </React.Fragment>
        ))
      )}
    </div>
  );
};

export default Home_page;
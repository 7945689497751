import React, { useEffect, useRef, useState } from "react";
import * as Styled from "./style";
import { GrBackTen, GrForwardTen } from "react-icons/gr";
import { IoPlay, IoVolumeMuteSharp } from "react-icons/io5";
import { FaRegCirclePause } from "react-icons/fa6";
import { MdFullscreen } from "react-icons/md";
import { FaVolumeUp } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import HttpApis from "../../services/HttpApis";
import Hls from "hls.js";
import useDetailsHook from "../../hooks/DetailsHook";
import useWindowSize from "../../hooks/windowScreenHook";
import { RiArrowGoBackLine } from "react-icons/ri";

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [title, setTitle] = useState();
  const [movieTitle, setMovieTitle] = useState();
  const [seriesData, setSeriesData] = useState();
  const [moviesData, setMoviesData] = useState();
  const [videoSource, setVideoSource] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { width } = useWindowSize();
  const params = useParams();
  const navigate = useNavigate();
  const { formatTime } = useDetailsHook();
  const handleBaackButton = () => {
    navigate(`/details/${params?.type}/${params?.id}`);
  };
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        let response;
        if (params.type === "series") {
          response = await HttpApis.getSeriesList(params.id);
          setSeriesData(response?.data?.data?.series?.seasons);
        } else {
          response = await HttpApis.getMoviesList(params.id);
          setMovieTitle(response?.data?.data?.movie?.title);
          setDuration(response?.data?.data?.movie?.duration);
          setMoviesData(
            response?.data?.data?.movie?.video_detail?.hls_playlist_url
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchDetails();
  }, [params.id]);

  useEffect(() => {
    if (params.type === "series") {
      const video = seriesData
        ?.map((item) =>
          item?.episodes.map((itemVideo) => {
            if (itemVideo.id == params.id) {
              setTitle(itemVideo?.title);
              setDuration(itemVideo?.duration);
              return itemVideo?.video_detail?.hls_playlist_url;
            }
            return null;
          })
        )
        .flat()
        .find((videoUrl) => videoUrl !== null);
      setVideoSource(video);
    } else if (params.type === "movie") {
      setVideoSource(moviesData);
    }
  }, [seriesData, moviesData, params.type, params.id]);

  useEffect(() => {
    if (videoSource && Hls.isSupported() && videoRef.current) {
      const hls = new Hls();
      hls.loadSource(videoSource);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {});
      hls.on(Hls.Events.ERROR, function (event, data) {
        console.error("HLS Error:", data);
      });
    } else if (
      videoSource &&
      videoRef.current.canPlayType("application/vnd.apple.mpegurl")
    ) {
      videoRef.current.src = videoSource;
    }
  }, [videoSource]);

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play().catch((error) => {
        console.error("Error playing the video:", error);
      });
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
  };

  const skipForward = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      videoRef.current.currentTime = currentTime + 5;
    }
  };

  const skipBackward = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      videoRef.current.currentTime = Math.max(0, currentTime - 5);
    }
  };

  const toggleMute = () => {
    if (videoRef.current.muted) {
      videoRef.current.muted = false;
      setIsMuted(false);
    } else {
      videoRef.current.muted = true;
      setIsMuted(true);
    }
  };

  const toggleFullscreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen();
    }
    setIsFullscreen(true);
  };

  const handleSeek = (e) => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.currentTime = e.target.value;
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.onloadedmetadata = () => {
        setDuration(videoElement.duration);
      };
    }
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const timeUpdateHandler = () => {
        setCurrentTime(videoElement.currentTime);
      };
      videoElement.addEventListener("timeupdate", timeUpdateHandler);

      return () => {
        videoElement.removeEventListener("timeupdate", timeUpdateHandler);
      };
    }
  }, []);

  const handleVolumeChange = (e) => {
    videoRef.current.volume = e.target.value;
  };
  const showNext = formatTime(duration - currentTime);
  return (
    <Styled.VideoContainer>
      <div className="backbutton" onClick={handleBaackButton}>
        <RiArrowGoBackLine className="backButtonIcon" /> Back
      </div>
      {/* <div className="episodeBox">
        <button className="episode">
          {" "}
          <IoPlay className="episodeIcon" />
          Next Episode
        </button>
      </div> */}
      {showNext == "20sec" && (
        <div className="episodeBox">
          <button className="episode">
            {" "}
            <IoPlay className="episodeIcon" />
            Next Episode
          </button>
        </div>
      ) }
      <div className="video-container">
        <video
          ref={videoRef}
          className="video-player"
          onEnded={handleVideoEnd}
          width="100%"
        >
          <source src={videoSource} type="application/x-mpegURL" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className={`controls ${isFullscreen ? "fullscreen" : ""}`}>
        <div>
          <div className="timeBox">
            <div>{formatTime(currentTime)}</div>
            <div>{formatTime(duration)}</div>
          </div>
          <div>
            <input
              type="range"
              min="0"
              max={duration || 0}
              value={currentTime}
              step="1"
              onChange={(e) => handleSeek(e)}
              className="seekWidth"
            />
          </div>
        </div>

        <Styled.IconBox>
          <div className="leftBox">
            <button onClick={togglePlayPause} className="iconStyle">
              {isPlaying ? <FaRegCirclePause /> : <IoPlay />}
            </button>
            <button onClick={skipBackward} className="iconcontainer">
              <GrBackTen className="iconStyle" />
            </button>
            <button onClick={skipForward} className="iconcontainer">
              <GrForwardTen className="iconStyle" />
            </button>
            <div onClick={toggleMute}>
              {isMuted ? (
                <IoVolumeMuteSharp className="iconStyle" />
              ) : (
                <FaVolumeUp className="iconStyle" />
              )}
            </div>
            <div className="volumeBox">
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                onChange={handleVolumeChange}
                defaultValue="1"
                className="volume"
              />
            </div>
          </div>
          {width > 650 ? <div>{movieTitle ? movieTitle : title}</div> : ""}

          <div>
            <button onClick={toggleFullscreen}>
              <MdFullscreen className="iconStyle" />
            </button>
          </div>
        </Styled.IconBox>
        {width < 650 ? <div>{movieTitle ? movieTitle : title}</div> : ""}
      </div>
    </Styled.VideoContainer>
  );
};

export default VideoPlayer;

import React from "react";
import * as Styled from "./GridStyle";

const Grid = ({ items, handleDetails }) => {

  return (
    <Styled.GridContainer>
      {items.slice(0, 25).map((item, index) => (
        <Styled.CardBox key={index}>
          <Styled.ImageContainer
            onClick={() => handleDetails(item.id, item.type)}
          >
            <img
              src={item.thumbnail_image}
              alt={item.title}
              className="imagebox"
            />
          </Styled.ImageContainer>
          <Styled.Para>{item.title}</Styled.Para>
        </Styled.CardBox>
      ))}
    </Styled.GridContainer>
  );
};

export default Grid;
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import useWindowSize from "../../hooks/windowScreenHook";
import Hero from "../Hero/Hero";
import useDetailsHook from "../../hooks/DetailsHook";


const Banner = (props) => {
  const { selectedSectionID, sectionData, handleDetails } = props;
  const [sliders, setSliders] = useState(null);
  const [rows, setRows] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const { width } = useWindowSize();
  // const{handleDetails}=useDetailsHook()

  useEffect(() => {
    const checkDataType = (data) => {
      if (Array.isArray(data)) {
        data.forEach((el) => {
          if (el.type.toLowerCase() === "carousel") {
            setSliders(el);
          } else {
            setRows(el);
          }
        });
      }
    };

    if (sectionData) {
      checkDataType(sectionData);
    }
  }, [sectionData]);
  useEffect(() => {
    if (sliders && sliders.items) {
      const currentItem = sliders.items[activeIndex];
      if (currentItem) {
        // setTitle(currentItem.title);
        // setDescription(currentItem.description);
      }
    }
  }, [activeIndex, sliders]);

  if (!selectedSectionID || !sectionData) {
    return <div>No section selected</div>;
  }
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false ,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => setActiveIndex(next),
  };

  return (
    <div>
      {width < 1200 ? (
        <>
          {" "}
          {sliders && sliders.items && sliders.items.length > 0 && (
            <div className="col-12  ">
              <div className="slider-container">
                <Slider {...sliderSettings}>
                  {sliders.items.map((item, index) => (
                    <div key={index} className="carousel-item">
                      <Hero
                        title={item.title}
                        info="14+ | 2024 | Drama"
                        description={item.description}
                        posterUrl={item.thumbnail_image}
                        data={item.id}
                        details={false}
                        type={item.type}
                        handleDetails={handleDetails}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {sliders && sliders.items && sliders.items.length > 0 && (
            <div className="col-12  ">
              <div className="slider-container">
                <Slider {...sliderSettings}>
                  {sliders.items.map((item, index) => (
                    <div key={index} className="carousel-item">
                      <Hero
                        title={item.title}
                        info={item.duration}
                        description={item.description}
                        posterUrl={item.thumbnail_image}
                        data={item.id}
                        details={false}
                        type={item.type}
                        handleDetails={handleDetails}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default Banner;

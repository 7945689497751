import React, { useState,useEffect } from "react";
import * as Styled from "./style";
import { Button } from "react-bootstrap";
import { MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../redux/slices/authSlice";
import HttpApis from "../../services/HttpApis";
import { Notyf } from "notyf";
import 'notyf/notyf.min.css'; 


const Login = () => {
  const [isLogin, setIsLogin] = useState(true); // Toggle between login and sign-up
  const [isForgotPassword, setIsForgotPassword] = useState(false); // Toggle for forgot password form
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState(""); // For sign-up
  const [phone, setPhone] = useState(""); // For sign-up
  const [error, setError] = useState("");
  const [message, setMessage] = useState(""); // To display success messages
  const [appLogo, setAppLogo] = useState("");
const navigate=useNavigate()
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const notyf = new Notyf({
    position: {
      x: "center",
      y: "center",
    },
  });

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await HttpApis.getConfig();
        if (response.data.status && response.data.data.App_setting) {
          setAppLogo(response.data.data.App_setting[0].app_logo)
        }
      } catch (err) {
      } 
    };
    fetchConfig();
    return () => {
    };
  }, []);

    const dispatch=useDispatch()
  const handleLoginClick = async () => {
    setError(""); // clear previous error messages
    if (!email || !password) {
      setError("Email and password cannot be empty");
      return;
    }
    if (!validateEmail(email)) {
      setError("Invalid email format");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);

      const response = await HttpApis.Login(formData, {
        headers: {
          Accept: "application/json",
        },
      }); 
      dispatch(loginSuccess(response));
      if (response.status === 200) {
          navigate("/")
          notyf.success('You have successfully Logged in');
      } else {
        setError("Invalid login credentials");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  const handleSignUpClick = async () => {
    setError("");
    if (!name || !email || !password || !phone) {
      setError("All fields are required");
      return;
    }
    if (!validateEmail(email)) {
      setError("Invalid email format");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("phone", phone);

      const response = await HttpApis.signUp(formData);

      if (response.status === 200) {

        setIsLogin(true); // Switch back to login after successful sign-up
      } else {
        setError("Sign-up failed. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  const handleForgotPasswordClick = async () => {
    setError("");
    if (!email) {
      setError("Email cannot be empty");
      return;
    }
    if (!validateEmail(email)) {
      setError("Invalid email format");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("email", email);
      const response = await HttpApis.forgotPassword(formData);
      if (response.status === 200) {
        setMessage("Password reset link has been sent to your email");
      } else {
        setError("Failed to send password reset email");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <Styled.LoginContainer>
      <Styled.MainContainer className="container">
      <div className="logo-container">
          <img
            src= {appLogo}
            alt="Logo"
            className="logo"
          />
        </div>
        <div className="formContainer">
          {isForgotPassword ? (
            <>
              <p className="para">Reset your password</p>
              <input
                placeholder="Enter your email"
                className="inputBox"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              {error && <p className="errorText">{error}</p>}
              {message && <p className="successText">{message}</p>}

              <Button className="btn" onClick={handleForgotPasswordClick}>
                Reset Password
              </Button>

              <Styled.ParaContainer>
                <div
                  className="paraText clickable"
                  onClick={() => setIsForgotPassword(false)}
                  style={{ cursor: "pointer" }}
                >
                  Back to Login{" "}
                  <span className="arrowIcon">
                    <MdKeyboardArrowRight />
                  </span>
                </div>
              </Styled.ParaContainer>
            </>
          ) : isLogin ? (
            <>
              <p className="para">Enter your account login</p>
              <input
                placeholder="Email"
                className="inputBox"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                placeholder="Password"
                type="password"
                className="inputBox"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              {error && <p className="errorText">{error}</p>}

              <Button className="btn" onClick={handleLoginClick}>
                Login
              </Button>

              <Styled.ParaContainer>
                <div
                  className="paraText clickable"
                  onClick={() => setIsForgotPassword(true)}
                  style={{ cursor: "pointer" }}
                >
                  Forgot your <span className="greenText">password</span>?
                </div>
                <div
                  className="paraText clickable"
                  onClick={() => setIsLogin(false)}
                  style={{ cursor: "pointer" }}
                >
                  Register for an account{" "}
                  <span className="arrowIcon">
                    <MdKeyboardArrowRight />
                  </span>
                </div>
                {/* <Styled.Line>
                  <hr className="line" /> <p className="text">or</p>{" "}
                  <hr className="line" />
                </Styled.Line> */}
              </Styled.ParaContainer>
              {/* <Button className="btnColor">KOODO | LOGIN</Button> */}
            </>
          ) : (
            <>
              <p className="para">Sign up for an account</p>
              <input
                placeholder="Name"
                className="inputBox"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                placeholder="Email"
                className="inputBox"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                placeholder="Phone"
                className="inputBox"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <input
                placeholder="Password"
                type="password"
                className="inputBox"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              {error && <p className="errorText">{error}</p>}

              <Button className="btn" onClick={handleSignUpClick}>
                Sign Up
              </Button>

              <Styled.ParaContainer>
                <div
                  className="paraText clickable"
                  onClick={() => setIsLogin(true)}
                  style={{ cursor: "pointer" }}
                >
                  Already have an account?{" "}
                  <span className="arrowIcon">
                    <MdKeyboardArrowRight />
                  </span>
                </div>
              </Styled.ParaContainer>
            </>
          )}
        </div>
      </Styled.MainContainer>
    </Styled.LoginContainer>
  );
};
export default Login;

import styled from "styled-components";
export const MainContainer = styled.div`
  text-align: center;
  width:35%;
  @media(max-width:1200px){
  width:100%}
  .para {
    font-size: 18px;
    text-align: left;
  }
  .paraText {
    margin: 0px;
  }
  .formContainer {
    display: flex;
    flex-direction: column;
  }
  .inputBox {
    font-size: 18px;
    padding: 10px 16px;
    margin-bottom: 16px;
    line-height: 28px;
    // width: 522px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.15);
    outline: none;
    border: none;
    color: #ffffff;
  }
  .btn {
    background: rgb(69, 139, 0);
    outline: none;
    border: none;
    padding: 10px 16px;
    font-size: 18px;
  }
  .greenText {
    color: rgb(69, 139, 0);
  }
  .arrowIcon {
    font-size: 24px;
  }
  .btnColor {
    background-color: rgba(255, 255, 255, 0.15);
    outline: none;
    border: none;
    padding: 10px 16px;
  }

  .logo-container {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    margin-bottom: 20px; /* Space between the logo and the form */
  }
  
.logo {
    width: 250px; /* Adjust the width according to your preference */
    height: auto;
}
  
.formContainer {
    text-align: center; /* Centers form content */
}
`;
export const Line = styled.div`
  margin-top: 10px;
  display: flex;
  .line {
    width: 48%;
  }
  .text {
    margin: 0px 10px;
  }
`;
export const ParaContainer = styled.div`
  margin: 25px 0px;
`;
export const LoginContainer = styled.div`
  color: #ffffff;
  background: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;


export const theme = {
  primary: {
    600: "#1a202c",
    900: "#171923"
  },
  grey: {
    300: "#d1d1d1",
    500: "#a0aec0",
    700: "#4a5568",
    900: "#1a202c"
  },
  white: "#fff",
  black: "#000",
  green: {
    300: "#2c7a7b",
    500: "#38a169",
    700: "#2f855a",
    900: "#22543d"
  },
  red: {
    300: "#fc8181",
    500: "#f56565",
    700: "#c53030",
    900: "#742a2a"
  },
  yellow: {
    300: "#faf089",
    500: "#ecc94b",
    700: "#d69e2e",
    900: "#975a16"
  },
  blue: {
    300: "#63b3ed",
    500: "#4299e1",
    700: "#3182ce",
    900: "#2b6cb0"
  },
  scrollbar: {
    border: "#ffffff",
    thumb: {
      bg: "#e1e1e1"
    }
  },
  loader: {
    teal: "#5DDADB",
    purple: "#3437A2",
    pink: "#F78EB6",
    bg: "#171923db"
  },
  gradient: {
    blue: {
      300: "#002eb3",
      600: "#002360",
      900: "#051937"
    }
  },
  text: {
    grey: {
      300: "#a0aec0",
      500: "#718096",
      700: "#4a5568",
      900: "#1a202c"
    },
    white: "#fff",
    black: "#000"
  },
  timeline: {
    divider: {
      bg: "#fff"
    }
  }
};

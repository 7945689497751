import { Outlet } from "react-router-dom";
import MenuItems from "../Navigation/MenuItems"; // Import the menu component

const Layout = ({ listData, handleSectionClick, error }) => {
  return (
    <div>
      {/* Menu at the top */}
      <MenuItems
        listData={listData}
        handleSectionClick={handleSectionClick}
        error={error}
      />
      {/* Page-specific content goes here */}
      <Outlet />
    </div>
  );
};

export default Layout;

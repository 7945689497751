
import React from "react";
import { useLocation } from "react-router-dom";
import Grid from "../Grid/grid";
import './FilterPage.css';

const FilterPage = ({ handleDetails }) => {
  const location = useLocation();
  const { searchResults } = location.state || { searchResults: [] }; // Get the search results from location state

  return (
    <div className="filter-container">
      {searchResults.length > 0 ? (
        <Grid items={searchResults} handleDetails={handleDetails} />
      ) : (
        <p className="no-results">No results found.</p>
      )}
    </div>
  );
};

export default FilterPage;

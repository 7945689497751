import React, { useState } from "react";
import "./Hero.css";

import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosAddCircleOutline } from "react-icons/io";
import { CiCircleCheck } from "react-icons/ci";
import { RiMovie2Line} from "react-icons/ri";
import { IoMdShare } from "react-icons/io";
import useDetailsHook from "../../hooks/DetailsHook";
import HttpApis from "../../services/HttpApis";
import { Modal } from "antd";
import { IoCopy } from "react-icons/io5";
import {BASE_BACKEND_URL} from "../../api/config"
// import * as Styled from "./Style"
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";
const Hero = (props) => {
  const {
    title,
    description,
    info,
    posterUrl,
    data,
    type,
    handleDetails,
    details,
    handlePlayRoute,
    slug,
  } = props;
  const loginUser = useSelector(
    (state) => state?.authReducer?.user?.data?.user
  );
  
 
  const navigate = useNavigate();
  const urlToShare = `${ BASE_BACKEND_URL }`; 
  const { formatTime } = useDetailsHook();
  const params = useParams();
 const [addFavData,setAddFavData]=useState(false)
  const authtoken = useSelector(
    (state) => state?.authReducer?.user?.data?.token
  );

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const showLoading = () => {
    setOpen(true);
    setLoading(true);

  
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleLogin = () => {
    navigate("/login");
  };
  const handleRemove =async () => {
    setAddFavData(false);

        const headers = {
          Authorization: `Bearer ${authtoken}`,
          "Content-Type": "application/json",
        };
        const params = { slug: slug };
        const response = await HttpApis.postRemovefavourite(params, headers);
        return response;
  };
  const handleAdd = async () => {
    setAddFavData(true);
   
      const headers = {
        Authorization: `Bearer ${authtoken}`,
        "Content-Type": "application/json",
      };
      const params = { slug: slug };
      const response = await HttpApis.postAddTofavourite(params, headers);
      return response;
    
  };

  const handleCopy = () => {
    const link = `${ BASE_BACKEND_URL }`; 

    const tempInput = document.createElement("input");
    tempInput.value = link;
    document.body.appendChild(tempInput);

    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  };

  return (
    <div className="hero-banner">
      <div className="hero-content">
        <h1 className="hero-title">{title}</h1>
        {info && info > 0 && <p className="hero-info">{formatTime(info)}</p>}
        <p className="hero-description">{description}</p>

        {details && loginUser ? (
          <div className="playButtonBox">
            <button
              className="hero-button"
              onClick={() => handlePlayRoute(params.id, params.type)}
            >
              Play
            </button>
            <div className="trailerBox">
              <RiMovie2Line
                className="addIcon"
                onClick={() => handlePlayRoute(params.id, params.type)}
              />
              {addFavData ? (
                <div onClick={handleRemove}>
                  <CiCircleCheck className="subtractIcon" />
                </div>
              ) : (
                <div onClick={handleAdd}>
                  <IoIosAddCircleOutline className="addIcon" />
                </div>
              )}
              <div>
                <IoMdShare className="subtractIcon" onClick={showLoading} />
              </div>
            </div>
          </div>
        ) : loginUser ? (
          <button
            className="hero-button"
            onClick={() => handleDetails(data, type)}
          >
            Details
          </button>
        ) : (
          <button className="hero-button" onClick={() => handleLogin(data)}>
            Login
          </button>
        )}
      </div>

      <div
        className="hero-background"
        style={{
          backgroundImage: `
      linear-gradient(to left, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 80%),
      linear-gradient(to bottom,rgba(0,0,0,0)60% , rgba(0,0,0,1)80%),
   
      url(${posterUrl})
    `,
          backgroundSize: "80vw , 40vh",
          marginLeft: "400px",
          backgroundPosition: "center",
          height: "80vh",
          width: "80vw",
        }}
      ></div>
      <Modal
        footer={null}
        loading={null}
        open={open}
        onCancel={() => setOpen(false)}
        closeIcon={null}
      >
        <FacebookShareButton url={urlToShare}>
          <FacebookIcon className="socialIcon" round />
        </FacebookShareButton>

        <LinkedinShareButton url={urlToShare}>
          <LinkedinIcon className="socialIcon" round />
        </LinkedinShareButton>

        <WhatsappShareButton url={urlToShare}>
          <WhatsappIcon className="socialIcon" round />
        </WhatsappShareButton>

        <a href="javascript:void(0);" onClick={handleCopy}>
          <IoCopy className="socialIcon" />
        </a>
      </Modal>
    </div>
  );
};

export default Hero;

import {
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  ProgramImage,
  useProgram
} from "planby";
import { useState } from "react";

export const ProgramItem = ({ program, setInfodta, ...rest }) => {
  const {
    styles,
    formatTime,
    set12HoursTimeFormat,
    isLive,
    isMinWidth
  } = useProgram({
    program,
    ...rest
  });
  
  const { data } = program;
  const { image, title, since, till, description } = data;

  const handleProgramClick = () => {
    setInfodta(program.data);
  };

  const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
  const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();

  return (
    <ProgramBox width={styles.width} style={styles.position  } >
      <ProgramContent width={styles.width} isLive={isLive}>
        <ProgramFlex onClick={handleProgramClick}>
          {isMinWidth && <ProgramImage src={image} alt="Preview" />}
          <ProgramStack>
            <ProgramTitle>{title}</ProgramTitle>
            <ProgramText>
              {sinceTime} - {tillTime}
            </ProgramText>
          </ProgramStack>
        </ProgramFlex>
      </ProgramContent>
    </ProgramBox>
  );
};

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HttpApis from "../services/HttpApis";
import { useDispatch } from "react-redux";
import { menuFailure, menuStart, menuSuccess } from "../redux/slices/menuSlice";

const useSectionHook = () => {
  const [selectedSectionID, setSelectedSectionID] = useState(null);
  const [sectionData, setSectionData] = useState([]);
  const [error, setError] = useState(null);
  const [details, setDetails] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchMenuList = async () => {
      try {
        dispatch(menuStart());
        const response = await HttpApis.getMenuList();
        response.data.data.push({
          name: "Guide",
          icon: "https://admin.vizzytv.com/icons/play_circle_24dp_000000_FILL0_wght400_GRAD0_opsz24.png",
          type: "section",
          section_id: 100,
        });
        dispatch(menuSuccess(response));
        handleSectionClick(response.data.data[0].section_id)
        return response;
      } catch (error) {
        dispatch(menuFailure(error.message));
      }
    };
    fetchMenuList();
  }, [dispatch]);

  const handleSectionClick = async (id) => {
    setSelectedSectionID(id); // Update selectedSectionID
    try {
      const response = await HttpApis.getSection(id);
      if (response.status === 200) {
        setSectionData(response.data.data); // Update sectionData
        navigate("/");
      } else {
        setSectionData([]);
      }
    } catch (error) {
      console.error("Error fetching section data:", error);
      setError(error.message);
      setSectionData([]);
    }
  };

  const handleDetails = async (id,type) => {
    setShowDetails(true);
    setError(null);
    try {
      let response;
      if (type === "series") {
        response = await HttpApis.getSeriesList(id);
        navigate(`/details/${type}/${id}`);
      } else {
        response = await HttpApis.getMoviesList(id);
        navigate(`/details/${type}/${id}`);
      }
      setDetails(response.data);
      setShowDetails(true);
    } catch (error) {
      setError(error.message);
      setDetails(null);
    }
  };

  return {
    handleSectionClick,
    selectedSectionID,
    sectionData,
    handleDetails,
    showDetails,
    details,
    error,
  };
};

export default useSectionHook;
import { createSlice } from "@reduxjs/toolkit";

const standardSlice = createSlice({
  name: "standard",
  initialState: {
    standardList: [],
    loading: false,
    error: null,
  },
  reducers: {
    standardStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    standardSuccess: (state, action) => {
      state.loading = false;
      state.standardList = action.payload.data;
    },
    standardFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { standardStart, standardSuccess, standardFailure } = standardSlice.actions;
export default standardSlice.reducer;

import { thunk } from "redux-thunk";
import authReducer from "./slices/authSlice";
import menuReducer from "./slices/menuSlice";
import standardReducer from "./slices/standardSlice";

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


const persistConfig = {
  key: "root",
  storage, 
};

const rootReducer = combineReducers({
  authReducer,
  menuReducer,
  standardReducer,
});


const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
});

export const persistor = persistStore(store);

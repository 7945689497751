import HttpMethod from "./HttpMethod";
import { BASE_BACKEND_URL } from "../api/config";

class HttpApis {
  async getMenuList(params) {
    return await HttpMethod._get(`${BASE_BACKEND_URL}menu`, params);
  }

  async getSection(sectionId) {
    return await HttpMethod._get(`${BASE_BACKEND_URL}section/${sectionId}`);
  }

  async getSeriesList(id) {
    return await HttpMethod._get(`${BASE_BACKEND_URL}series/${id}`);
  }

  async getMoviesList(id) {
    return await HttpMethod._get(`${BASE_BACKEND_URL}movies/${id}`);
  }

  async Login(params) {
    return await HttpMethod._post(`${BASE_BACKEND_URL}login`, params);
  }
  async postAddTofavourite(params, headers) {
    return await HttpMethod._post(
      `${BASE_BACKEND_URL}add-favourite`,
      params,
      headers
    );
  }
  async postRemovefavourite(params, headers) {
    return await HttpMethod._post(
      `${BASE_BACKEND_URL}remove-favourite`,
      params,
      headers
    );
  }

  async getChanelsWithScheduls() {
    return await HttpMethod._get(
      `${BASE_BACKEND_URL}active-channels-with-schedules`
    );
  }

  async getConfig() {
    return await HttpMethod._get(`${BASE_BACKEND_URL}config`);
  }

  async getSearchResults(query) {
    return await HttpMethod._get(
      `${BASE_BACKEND_URL}search?search_text=` + query
    );
  }

  async signUp(params) {
    return await HttpMethod._post(`${BASE_BACKEND_URL}signup`, params);
  }

  async forgotPassword(params) {
    return await HttpMethod._post(`${BASE_BACKEND_URL}forgot-password`, params);
  }

  async getPages() {
    return await HttpMethod._get(`${BASE_BACKEND_URL}pages`);
  }
}

export default new HttpApis();

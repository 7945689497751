import styled from "styled-components";

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 10px;
  justify-content: space-between;
  .iconStyle {
    color: #ffffff;
    font-size: 24px;
  }
  .iconcontainer {
    background: none;
    border: none;
  }
  .leftBox {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  .backbutton {
    position: absolute;
    left: 45px;
    top: 23px;
    text-align: center;
    justify-content: center;
    display: flex;
    gap: 10px;
    cursor: pointer;
  }
  .backButtonIcon {
    font-size: 24px;
  }

  .video-container {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .video-player {
    width: 1100px;
    @media (max-width: 1100px) {
      width: 100%;
    }
  }

  .controls {
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: -86px;
    @media (max-width: 650px) {
      bottom: -116px;
    }
  }

  button {
    background: none;
    color: white;
    border: none;
    cursor: pointer;
  }

  input[type="range"] {
    width: 50px;
  }

  .video-container {
    width: 100%;
    height: auto;
  }
  .volumeBox {
    display: flex;
    align-items: center;
  }
  .seekWidth {
    width: 98% !important;
  }
  .timeBox {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
  }
  .episodeBox {
    position: absolute;
    right: 8px;
    bottom: 155px;
    width: 10%;
  }

  .episode {
    display: flex;
    align-items: center;
    position: absolute;
    background: #fff;
    color: #000;
    border-radius: 3px;
    padding: 7px;
  }
  .episodeIcon {
    font-size: 24px;
  }
`;

import React, { useEffect, useState } from "react";
import * as Styled from "./style";

const Standard = (props) => {
  const { sectionData, detailspage, detailList,handleDetails } = props;
  const [galleryRows, setGalleryRows] = useState([]);
  useEffect(() => {
    if (Array.isArray(sectionData)) {
      const galleryItems = sectionData.filter(
        (item) => item.type.toLowerCase() === "gallery"
      );
      setGalleryRows(galleryItems);
    }
  }, [sectionData]);

  return (
    <div>
      {detailspage ? (
        <>
          {detailList &&  (
            <Styled.StandardBox>
              <Styled.HeadingBox className="heading">
                More like this
              </Styled.HeadingBox>
              <Styled.MainContainer>
                {detailList?.map((item, index) => (
                  <div key={index} className="cardbox">
                    <div
                      onClick={() => handleDetails(item.id, item.type)}
                      className="imageContainer"
                    >
                      <img
                        src={item.thumbnail_image}
                        alt=""
                        className="imagebox"
                      />
                    </div>
                    <div className="para">{item.title}</div>
                  </div>
                ))}
              </Styled.MainContainer>
            </Styled.StandardBox>
          )}
        </>
      ) : (
        <>
          {galleryRows.length > 0 ? (
            galleryRows.map((row, rowIndex) => (
              <Styled.StandardBox key={rowIndex}>
                <Styled.HeadingBox className="heading">
                  {row.title}
                </Styled.HeadingBox>
                <Styled.MainContainer>
                  {row.items.map((item, index) => (
                    <div key={index} className="cardbox">
                      <div
                        onClick={() => handleDetails(item.id, item.type)}
                        className="imageContainer"
                      >
                        <img
                          src={item.thumbnail_image}
                          alt=""
                          className="imagebox"
                        />
                        
                      </div>
                     
                      <div className="para">{item.title}</div>
                      
                    </div>
                  ))}
                </Styled.MainContainer>
              </Styled.StandardBox>
            ))
          ) : (
            <div>No gallery items available</div>
          )}
        </>
      )}
    </div>
  );
};

export default Standard;
